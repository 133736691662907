<template>
	<div>
		<CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="12">

				<CCard>
    			<CCardBody>
						<TheTableHeader
							:header="'DATA PERUSAHAAN'"
							:subHeader="'DAFTAR INSTANSI/PERUSAHAAN DENGAN AKSES KE RUANGAN DI DATA CENTER LINTASARTA .'"
							buttonShow
							:buttonText="'Add New'"
							:buttonUrl="'companies/form/0'"
						/>
					</CCardBody>
				</CCard>
				
				<CCard>
					<CCardBody>
						<CDataTable
							hover
							striped
							border
							small
							tableFilter
							sorter
							itemsPerPageSelect
							:items="companies"
							:fields="fields"
							:items-per-page="5"
							pagination
							:loading="isLoading"
						>
							<!-- <template #status="{item}">
								<td>
									<CBadge :color="getBadge(item.inActive)">{{item.inActive}}</CBadge>
								</td>
							</template> -->
							<template #id="{item}">
								<td align="center">{{companies.map(function(x) {return x.id; }).indexOf(item.id)+1}}</td>
							</template>
							<template #created_at="{item}">
								<td align="center">
									<p>{{item.created_at | formatDate}}  {{item.created_at | formatTime}}</p>
								</td>
							</template>
							<template #action="{item}">
            		<td align="center">
              		<CButton color="primary" variant="outline" square size="sm" @click="editCompany(item.id)">Edit</CButton> &nbsp; 
									<CButton color="danger" variant="outline" square size="sm" @click="removeCompany(item.id)">Delete</CButton>
            		</td>
          		</template>
						</CDataTable>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>	
</template>

<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'TableMasterCompany',
	components: { TheTableHeader },
	data () {
		return {
			role: localStorage.getItem('role'),
			companies: [],
			fields: [
				{ key: 'id', label: 'No' }, 
				{ key: 'crm_id', label: 'CRM ID' }, 
				{ key: 'id_sa', label: 'SA ID' }, 
				{ key: 'company_name', label: 'End User' }, 
				{ key: 'email', label: 'Company Name' },  
				'phone', 
				'address', 
				'created_at', 
				'action'
			],
			currentPage: 1,
			perPage: 5,
			totalRows: 1,
			isLoading: true,
			links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Daftar Perusahaan',
        }
      ],
		}
	},
	paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
	methods: {
		toast(message, type) {
      this.$toast.open({
        message: message,
        type: type, // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
		goCreate() {
      this.$router.push({ path: 'companies/form/0' });
    },
		getBadge (status) {
			return status === 1 ? 'success' : 'danger'
		},
		getCompanies() {
			let self = this;
			return http.get('/companies')
			.then(function (response) {
				self.companies = response.data.data;
				self.isLoading= false;
			}).catch(function (error) {
				console.log(error);
			});
		},
		editCompany(id) {
      this.$router.push({ path: 'companies/form/' + id });
    },
		removeCompany(id) {
			let self = this;
			if (confirm('Yakin Ingin Menghapus ?')) {
    		return http.delete('/companies/' + id)
					.then(function (response) {
						self.getCompanies();
						self.toast('Berhasil Menghapus Perusahaan Dari Daftar.', 'info');
						// console.log(response);
					}).catch(function (error) {
						console.log(error);
					});
  		}
		}
	},
	mounted: function(){
		this.getCompanies();
	}
}

</script>
